import React, { useState } from 'react'
import './portfolio.css'

import IMG1 from '../../assets/food-del.png'
import IMG2 from '../../assets/green-economy.png'
import IMG3 from '../../assets/berks-inulation.png'
import IMG4 from '../../assets/align-radiance.png'
import IMG5 from '../../assets/mdinnovation-center.jpg'
import IMG6 from '../../assets/portfolio6.jpg'
import IMG7 from '../../assets/Rsweb.jpg'
import IMG8 from '../../assets/Printing-press.jpg'
import IMG9 from '../../assets/Skin Perfection.jpg'
import IMG10 from '../../assets/the mobile vine.jpg'
import IMG11 from '../../assets/klassico inks.jpg'
import IMG12 from '../../assets/Updater.jpg'
import IMG13 from '../../assets/point-innovation.jpg'
import IMG14 from '../../assets/shorter-investment.jpg'
import IMG15 from '../../assets/beloon-decor.jpg'
import IMG16 from '../../assets/ecolox-tech.jpg'
import IMG17 from '../../assets/Air-vistas.png'
import IMG18 from '../../assets/Kiveton.jpg'


// DO NOT USE THE IMAGE IN PRODUCTION

const data = [
    {
        id: 1,
        Image: IMG1,
        title: 'Food Delivery React App',
        github: 'https://github.com/Umair-asif84/MERN-Stack-Food-App-Redux',
        demo: 'https://github.com/Umair-asif84/MERN-Stack-Food-App-Redux'
    },
    {
        id: 2,
        Image: IMG2,
        title: 'Green Economy React App',
        github: 'https://github.com/Umair-asif84/Green-Economy',
        demo: 'https://github.com/Umair-asif84/Green-Economy'
    },
    {
        id: 3,
        Image: IMG3,
        title: 'Berks Insulation React App',
        github: 'https://github.com/Umair-asif84/Dental-Appointment-SPA',
        demo: 'https://github.com/Umair-asif84/Dental-Appointment-SPA'
    },
    {
        id: 4,
        Image: IMG4,
        title: 'Align Radiance React App',
        github: 'https://github.com/Umair-asif84/Dental-Appointment-SPA',
        demo: 'https://thealignradiance.netlify.app/' 
    },
    {
        id: 5,
        Image: IMG5,
        title: 'Maryland Innovation Center',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://www.mdinnovationcenter.com/'
    },
    {
        id: 6,
        Image: IMG6,
        title: 'Rare Munchiez',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://raremunchiez.com/'
    },
    {
        id: 7,
        Image: IMG7,
        title: 'RS Web',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://rswebbs.co.uk/'
    },
    {
        id: 8,
        Image: IMG8,
        title: 'Arabian Printing Press',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://www.arabianprintingpress.com/'
    },
    {
        id: 9,
        Image: IMG9,
        title: 'Skin Perfection',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://www.skinperfectionhdltd.com/'
    },
    {
        id: 10,
        Image: IMG10,
        title: 'The Mobile Vine',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://host.themobilevine.com/'
    },
    {
        id: 11,
        Image: IMG11,
        title: 'Klassico Inks',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://www.klassicoinks.com/'
    },
    {
        id: 12,
        Image: IMG12,
        title: 'Updater',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://updater.com/'
    },
    {
        id: 13,
        Image: IMG13,
        title: 'Point Innovation',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://pointinnovation.com/'
    },
   
    {
        id: 14,
        Image: IMG14,
        title: 'Shorter Investment',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://www.shorterinvestmentgroup.com/'
    },

    {
        id: 15,
        Image: IMG15,
        title: 'Beloon Decor',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://beloondecor.com/'
    },
    {
        id: 16,
        Image: IMG16,
        title: 'Ecolox Tech',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://ecoloxtech.com/'
    },
    {
        id: 17,
        Image: IMG17,
        title: 'Air Vistas',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://airvistas.ae/'
    },
    {
        id: 18,
        Image: IMG18,
        title: 'Kiveton Pharmacy',
        github: 'https://github.com/Umair-asif84',
        demo: 'https://kivetonpharmacy.com/'
    }
    
]

const portfolio = () => {
    const [showAllItems, setShowAllItems] = useState(false);

    return (
        <section id="portfolio">
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>

            <div className='container portfolio__container'>
                {
                    data.slice(0, showAllItems ? data.length : 6).map(({ id, Image, title, github, demo }) => {
                        return (
                            <article key={id} className='portfolio__item'>
                                <div className="portfolio__item-image">
                                    <img src={Image} alt={title} />
                                </div>
                                <h3>
                                    {title}
                                </h3>
                                <div className="portfolio__item-cta">
                                    <a href={github} className='btn'> Github</a>
                                    <a href={demo} className='btn btn-primary' target='_black'>Live Demo</a>
                                </div>
                            </article>
                        )
                    })
                }

            </div>
            <div className='view-more'>
                <button onClick={()=>setShowAllItems(!showAllItems)}>
                    {showAllItems ? 'View Less' : 'View More'}
                </button>
            </div>
        </section>

    )
}

export default portfolio