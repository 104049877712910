import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
    {
        avatar: AVTR1,
        name: 'Imtiaz Rana',
        review: 'Working with Umair to create our website was a fantastic experience. We highly recommend their services to any business looking for a professional, effective online presence.'
    },
    {
        avatar: AVTR2,
        name: 'Faizan',
        review: 'Partnering with Muhammad for our website development was a game-changer for RSWEBBS. Their expertise and dedication helped us create a professional online presence that truly reflects our innovative IT solutions.'
    },
    {
        avatar: AVTR3,
        name: 'Maria',
        review: 'He has been a fantastic partner in developing our eCommerce website. Their expertise shine through in every aspect of the project. I highly recommend Umair for anyone looking to elevate their online business!'
    },
    {
        avatar: AVTR4,
        name: 'Ayesh',
        review: 'Muhammad is an incredibly passionate and dedicated professional. Whenever I requested changes to our Skin Perfection website, they were always responsive and accommodating, no matter the time of day.'
    },
]

const testimonials = () => {
    return (

        <section id='testimonials'>
            <h5>Review from clients</h5>
            <h2>Testimonials</h2>

            <Swiper className="container testimonials__container"
                // install Swiper modules
                modules={[Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}>
                {
                    data.map(({ avatar, name, review }, index) => {
                        return (
                            <SwiperSlide key={index} className='testimonial'>
                                <div className="client__avatar">
                                    <img src={avatar} alt="my-client" />
                                </div>
                                <h5 className="client__name">{name}</h5>
                                <small className="client__review">{review}</small>

                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </section>
    )
}

export default testimonials