import React from 'react'
import './footer.css'
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'

const footer = () => {
    return (
        <footer>
            

            <ul className='permalinks'>
                <li><a href="#">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#experience">Experience</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#testimonials">Testimonials</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>

            <div className="footer__socials">
                <a href='https://linkedin.com/in/umair-asif-bezteq/' target='_blank'><BsLinkedin /></a>
                <a href='https://github.com/Umair-asif84' target='_blank'><FaGithub /></a>
            </div>
            <div className="footer__copyright">
                <small>&copy; Umair 2021. All rights reserved.</small>
            </div>
        </footer>
    )
}

export default footer;