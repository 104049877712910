import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'

const about = () => {
    return (
        <section id='about'>
            <h5>Get To Get</h5>
            <h2>About Me</h2>
            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-img">
                        <img src={ME} alt="Umair" />
                    </div>
                </div>

                <div className="about__content">
                    <div className="about__cards">
                        <article className='about__card'>
                            <FaAward className='about-icon' />
                            <h5>Experience</h5>
                            <small>4 Years working</small>
                        </article>

                        <article className='about__card'>
                            <FiUsers className='about-icon' />
                            <h5>Clients</h5>
                            <small>50+ Clients</small>
                        </article>

                        <article className='about__card'>
                            <VscFolderLibrary className='about-icon' />
                            <h5>Projects</h5>
                            <small>80+ Projects</small>
                        </article>
                    </div>

                    <p>I am a full-stack developer specializing in creating web solutions for various industries. My expertise includes both front-end and back-end development, allowing me to design and deploy systems that meet different business needs. I focus on crafting user-friendly interfaces and building strong back-end solutions that improve operational efficiency and enhance customer experiences.

In addition to my technical skills, I stay updated with the latest industry trends to ensure my technology strategies are innovative and effective. My goal is to provide solutions that address both current challenges and future opportunities. If you’re looking for a developer who can turn your ideas into impactful, reliable web solutions, I’d be happy to discuss how I can assist.

</p>

                    <a href="#contact" className='btn btn-primary'>Let's Talk</a>

                </div>
            </div>
        </section>

    )
}

export default about

